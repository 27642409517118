<template>
    <div class="mt-6 mb-6 pb-6">
<!-- Google tag (gtag.js)
<script type="application/javascript" async src="https://www.googletagmanager.com/gtag/js?id=AW-16640204719">
</script>
<script  type="application/javascript">
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-16640204719');
</script> -->
        <vue-lottie :options="lottieOptions" :width="400" :height="200" />
    
        <div class="has-text-centered">
    
            <p class="has-text-weight-bold is-size-4 mb-2">Your quote request was sent to our team.</p>
    
            <p class="is-size-5 mb-6">One of our experts will reach out to you soon!</p>
    
        </div>
    
    </div>
</template>

<script>
import VueLottie from 'vue-lottie';
export default {
    components: {
        VueLottie,
    },
    data() {
        return {
            lottieOptions: {
                animationData: require('@/assets/animations/success.json'),
                loop: false,
                autoplay: true,
            },
        }
    }
}
</script>